<template>
      <div class="productSpecsElementCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecsElement', params: {} }">
          Product Specs Element
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'productSpecsElementCreate', params: {} }">
            Create Product Specs Element
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Product Specs Element</v-card-title>
          <v-card-text>
            <productSpecsElement-form></productSpecsElement-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import ProductSpecsElementForm from './ProductSpecsElementForm.vue';

    export default {
      name: 'productSpecsElement',
      components: {
        ProductSpecsElementForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    